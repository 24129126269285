import React from "react";
import {
  CrossRefContextModel,
  CrossRefReturnHeaderCell,
  useCrossRefContext,
} from "../CrossRefReturn";

interface Props {
  data: CrossRefReturnHeaderCell[];
}

export const CrossRefTableHeader = ({ data }: Props) => {
  const { CrossRefData, setCrossRefData } =
    useCrossRefContext() as CrossRefContextModel;

  const { sortKey, sortDirection } = CrossRefData;

  const sortIcon = (enabled: boolean, key: string) => {
    if (!enabled) {
      return null;
    }

    if (key === sortKey) {
      if (sortDirection === "ASC") {
        return (
          <span
            className="sort-header"
            onClick={() => {
              setCrossRefData({
                ...CrossRefData,
                sortKey: key,
                sortDirection: "DESC",
              });
            }}
          >
            &#8679;
          </span>
        );
      }
      return (
        <span
          className="sort-header"
          onClick={() => {
            setCrossRefData({
              ...CrossRefData,
              sortKey: "",
              sortDirection: "",
            });
          }}
        >
          &#8681;
        </span>
      );
    }

    return (
      <span
        className="sort-header sort-hover"
        onClick={() => {
          setCrossRefData({
            ...CrossRefData,
            sortKey: key,
            sortDirection: "ASC",
          });
        }}
      >
        &#8691;
      </span>
    );
  };

  return (
    <thead>
      <tr>
        {data.map((hdr) => (
          <th scope="col" key={hdr.key}>
            {hdr.name}
            {sortIcon(hdr.isSortEnabled, hdr.key)}
          </th>
        ))}
      </tr>
    </thead>
  );
};
