import React from "react";
import { ContentBindingModel } from "../ContentReturn";

interface Props {
  contentData: ContentBindingModel;
  setContentData: Function;
}

export const ContentFilterSection = ({
  contentData,
  setContentData,
}: Props) => {
  return (
    <div style={{ marginTop: "1em" }}>
      <div>
        <span>Sort Results by</span>
        <select
          className="form-select"
          value={contentData.sort || ""}
          onChange={(e) =>
            setContentData({
              ...contentData,
              sort: e.target.value || "",
            })
          }
        >
          <option value="">Default</option>
          <option>ASC</option>
          <option>DESC</option>
        </select>
      </div>
    </div>
  );
};
