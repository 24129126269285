import React from "react";
import {
  ProductSearchContextModel,
  ProductSearchReturn,
  useProductSearchContext,
} from "../ProductSearchReturn";

interface Props {
  data: ProductSearchReturn;
  filterGroupsOpen: Set<string>;
  setFilterGroupsOpen: Function;
}

export const ProductSearchFilter = ({
  data,
  filterGroupsOpen,
  setFilterGroupsOpen,
}: Props) => {
  const currentKey = data?.productFilter?.key || "";
  const filters = data?.productOption?.optionList || [];
  const { productSearchData, setProductSearchData } =
    useProductSearchContext() as ProductSearchContextModel;

  if (!data.totalCnt) {
    return null;
  }

  return (
    <div className="product-search-filters">
      <h4 className="text-secondary">Filters</h4>
      {filters.map((filter) => {
        return (
          <div
            className="accordion"
            id={`accordion${filter.displayName.replaceAll(" ", "_")}`}
            key={filter.key}
          >
            <div className="accordion-item mb-3">
              <h2
                className="accordion-header"
                id={`heading${filter.displayName.replaceAll(" ", "_")}`}
              >
                <button
                  className={`accordion-button ${
                    filterGroupsOpen.has(filter.key) ? "" : "collapsed"
                  }`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${filter.displayName.replaceAll(
                    " ",
                    "_"
                  )}`}
                  aria-controls={`collapse${filter.displayName.replaceAll(
                    " ",
                    "_"
                  )}`}
                  onClick={() => {
                    const newSet: Set<string> = new Set<string>(
                      filterGroupsOpen
                    );
                    if (newSet.has(filter.key)) {
                      newSet.delete(filter.key);
                    } else {
                      newSet.add(filter.key);
                    }
                    setFilterGroupsOpen(newSet);
                  }}
                >
                  {filter.displayName} (
                  {filter.childrenList.reduce(
                    (total, child) => (total += child.cnt),
                    0
                  )}
                  )
                </button>
              </h2>
              <div
                className={`accordion-collapse collapse ${
                  filterGroupsOpen.has(filter.key) ? "show" : "collapsed"
                }`}
                id={`collapse${filter.displayName.replaceAll(" ", "_")}`}
                aria-labelledby={`heading${filter.displayName.replaceAll(
                  " ",
                  "_"
                )}`}
                data-bs-parent={`#accordion${filter.displayName.replaceAll(
                  " ",
                  "_"
                )}`}
              >
                <div className="accordion-body">
                  {filter.childrenList.map((child) => (
                    <div className="product-search-filter" key={child.key}>
                      <input
                        id={child.productTypeKey}
                        type="checkbox"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setProductSearchData({
                              ...productSearchData,
                              Key: child.productTypeKey,
                            });
                          }
                        }}
                        checked={currentKey === child.productTypeKey}
                        disabled={!child.isEnabled}
                        className="form-check-input"
                      />
                      <label
                        htmlFor={child.productTypeKey}
                        className="form-check-label"
                      >
                        {child.displayName} ({child.cnt})
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
