/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

interface Props {
  count: number;
  pages: number;
  currentPage: number;
  updatePageNumber: Function;
}

export const Pagination = ({
  count,
  pages,
  currentPage,
  updatePageNumber,
}: Props) => {
  const pageNumbers = [];
  for (let i = 0; i < pages; i++) {
    pageNumbers.push(
      <option className="dropdown-option" key={`page-${i}`} value={i}>
        Page {i + 1}
      </option>
    );
  }

  if (pages === 0) {
    return null;
  }

  return (
    <div className="row">
      <div className="col-12">
        <nav aria-label="...">
          <ul className="pagination align-items-top mt-3">
            <li
              className={
                currentPage === 0 ? "page-item me-1 disabled" : "page-item me-1"
              }
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (currentPage !== 0) {
                  updatePageNumber(currentPage - 1);
                }
              }}
            >
              <a className="page-link d-flex align-items-center">
                <i className="me-2 svg-arrow-right rotate-180"></i> Previous
              </a>
            </li>
            <div className="d-flex flex-column">
              <select
                className="form-select mt-2"
                onChange={(e) => updatePageNumber(+e.target.value)}
                value={currentPage}
              >
                {pageNumbers}
              </select>
              <p className="mt-2 mb-0 text-center small">
                Page {currentPage + 1} of {pages === 0 ? 1 : pages}
              </p>
            </div>
            <li
              className={
                currentPage === (pages === 0 ? 1 : pages) - 1
                  ? "page-item ms-1 me-1 disabled"
                  : "page-item ms-1 me-1"
              }
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (currentPage !== (pages === 0 ? 1 : pages) - 1) {
                  updatePageNumber(currentPage + 1);
                }
              }}
            >
              <a className="page-link d-flex align-items-center">
                Next <i className="ms-2 svg-arrow-right"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};
