import { http } from "./http";
import React from "react";

export interface ContentReturn {
  keyword: string;
  pageSize: number;
  pageCnt: number;
  pageCur: number;
  totalCnt: number;
  resultList: ContentReturnResult[];
}

export interface ContentReturnResult {
  name: string;
  type: string;
  description: string;
  url: string;
  iconClass: string;
}

export interface ContentBindingModel {
  keyword?: string;
  sort?: string;
  pageSize?: number;
  pageNumber?: number;
}

export const getContentReturnAsync = async (
  binding: ContentBindingModel
): Promise<ContentReturn | undefined> => {
  const result = await http<ContentReturn>({
    path: `/search/content?keyword=${binding.keyword || ""}&sort=${
      binding.sort || ""
    }&pageSize=${binding.pageSize || ""}&pageNumber=${
      binding.pageNumber || ""
    }`,
    method: "GET",
  });
  if (result.ok && result.body) {
    return result.body;
  } else {
    return undefined;
  }
};
