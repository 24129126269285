import React from "react";
import { ResourceTable } from "./ResourceTable";
import { ResourceFilterSection } from "./ResourceFilterSection";
import {
  ResourceReturn,
  ResourceBindingModel,
  getResourceReturnAsync,
} from "../ResourceReturn";
import { Pagination } from "../Pagination";
import { Loading } from "../Loading";
import { lookForTable } from "../init-sticky-header";

interface Props {
  sourceKey: string;
  searchKey: string;
  pageSize: number;
}

export const ResourceFilterRoot = ({
  sourceKey,
  searchKey = "",
  pageSize,
}: Props) => {
  const defaultBindingModel: ResourceBindingModel = {
    resourceTypeCode: undefined,
    typeCategoryId: sourceKey === "" ? undefined : parseInt(sourceKey),
    subtypeCategoryId: undefined,
    productCategoryId: undefined,
    location: undefined,
    keyword: searchKey,
    sort: undefined,
    pageSize: pageSize,
    pageNumber: 0,
  };
  const [resourceFilterData, setResourceFilterData] =
    React.useState<ResourceBindingModel>(defaultBindingModel);
  const [result, setResult] = React.useState<ResourceReturn>();
  const [searchKeyword, setSearchKeyword] = React.useState<string>(searchKey);
  const [loading, setLoading] = React.useState(true);

  const updatePageNumber = (pageNumber: number) => {
    setResourceFilterData({
      ...resourceFilterData,
      pageNumber,
    });
  };

  React.useEffect(() => {
    lookForTable(0);
  });

  React.useEffect(() => {
    setLoading(true);
    const doGetResourceReturn = async (binding: ResourceBindingModel) => {
      const result = await getResourceReturnAsync(binding);
      setResult(result);
      setLoading(false);
    };
    doGetResourceReturn(resourceFilterData);
  }, [resourceFilterData]);
  return (
    <div>
      <div>
        <section id="variable">
          <div className="container mb-5">
            <div className="row">
              <div className="col-12">
                <div className="col-12 col-md-8">
                  <form
                    className="search search-hero"
                    onSubmit={(e) => {
                      e.preventDefault();
                      setResourceFilterData({
                        ...resourceFilterData,
                        keyword: searchKeyword,
                        typeCategoryId: undefined,
                        subtypeCategoryId: undefined,
                        productCategoryId: undefined,
                        location: "",
                        pageNumber: 0,
                      });
                    }}
                  >
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        aria-label="Search Resources"
                        placeholder="What are you looking for?"
                        value={searchKeyword}
                        onChange={(e) => {
                          setSearchKeyword(e.target.value);
                        }}
                      />
                      <button
                        className="btn btn-primary p-3 btn-search"
                        type="submit"
                      >
                        <i className="bi bi-search"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div className="container">
              <div className="row mt-4">
                <ResourceFilterSection
                  data={result!.filter}
                  results={result!.resultList}
                  resourceFilterData={resourceFilterData}
                  setResourceFilterData={setResourceFilterData}
                />
                {result && !result.totalCnt ? (
                  <div className="col-12 col-md-9 justify-content-center text-center">
                    <h3 className="text-secondary">
                      Sorry, No Results Were Found
                    </h3>
                    <p>
                      We suggest checking your part number and spelling, or
                      trying a more general keyword
                    </p>
                  </div>
                ) : (
                  <>
                    <div className="col-12 col-md-9 resource-results">
                      <h4 className="text-secondary">Results</h4>
                      <p className="mb-0 small">
                        {result?.totalCnt} Results Found
                      </p>
                      <ResourceTable
                        data={result!.resultList}
                        resourceFilterData={resourceFilterData}
                        setResourceFilterData={setResourceFilterData}
                      />
                      <div className="container mb-6">
                        <Pagination
                          count={result?.totalCnt || 0}
                          pages={result?.pageCnt || 0}
                          currentPage={result?.pageCur || 0}
                          updatePageNumber={updatePageNumber}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </section>
      </div>
    </div>
  );
};
