import React from "react";
import { LocationReturn } from "../LocationReturn";
import { LocationCard } from "./LocationCard";

interface Props {
  results?: LocationReturn;
}

export const LocationResults = ({ results }: Props) => {
  if (results && results?.totalCnt > 0) {
    return (
      <section id="variable">
        <div className="container mt-5 locations-listing">
          <div className="row">
            {results.resultList.map((r) => {
              return <LocationCard key={r.key} location={r} />;
            })}
          </div>
        </div>
      </section>
    );
  }

  return (
    <div className="justify-content-center text-center mt-5">
      <h3 className="text-secondary">Sorry, No Results Were Found</h3>
    </div>
  );
};
