import React from "react";
import { ContentResultSection } from "./ContentResultSection";
import { ContentReturn, ContentBindingModel } from "../ContentReturn";

interface Props {
  result: ContentReturn | undefined;
  contentData: ContentBindingModel;
  setContentData: Function;
}

export const ContentSearchRoot = ({
  result,
  contentData,
  setContentData,
}: Props) => {
  return (
    <>
      {result && !result.totalCnt ? (
        <div className="row mt-4">
          <div className="justify-content-center text-center">
            <h3 className="text-secondary">Sorry, No Results Were Found</h3>
            <p>
              We suggest checking your part number and spelling, or trying a
              more general keyword
            </p>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="row mt-n3">
            <ContentResultSection data={result!.resultList} />
          </div>
        </div>
      )}
    </>
  );
};
