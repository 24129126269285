import React, { useEffect } from "react";
import {
  ProductFilterReturnFilterCell,
  ProductFilterContextModel,
  useProductFilterContext,
  ProductFilterBindingFilter,
} from "../ProductFilterReturn";

interface Props {
  data: ProductFilterReturnFilterCell;
}

export const ProductFilterInputCheckbox = ({ data }: Props) => {
  const { productFilterData, setProductFilterData } =
    useProductFilterContext() as ProductFilterContextModel;

  const [checkedValue, setCheckedValue] = React.useState<boolean>(false);

  useEffect(() => {
    const existingFilter = productFilterData.FilterList.find(
      (f) => f.name === data.group
    );

    const currentCheckedValue = existingFilter?.value === "true";
    setCheckedValue(currentCheckedValue);
  }, [productFilterData, data]);

  return (
    <div className="mb-3 d-flex">
      <div className="flex-grow-1 w-100">
        <div className="form-check">
          <label className="form-label" htmlFor={data.key}>
            {data.displayName}
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            id={data.key}
            checked={checkedValue}
            onChange={(e) => {
              let newFilterList = [...productFilterData.FilterList];
              if (e.target.checked) {
                const newFilter: ProductFilterBindingFilter = {
                  name: data.group,
                  nameMin: data.minName,
                  nameMax: data.maxName,
                  value: "true",
                  valueMax: "",
                  filterType: data.type,
                };
                newFilterList.push(newFilter);
              } else {
                newFilterList = newFilterList.filter(
                  (f: ProductFilterBindingFilter) => f.name !== data.group
                );
              }

              setProductFilterData({
                ...productFilterData,
                FilterList: newFilterList,
                PageNumber: 0,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
