import { http } from "./http";
import React from "react";

export interface ProductSearchReturn {
  keyword: string;
  pageSize: number;
  totalCnt: number;
  productOption: ProductSearchReturnOption;
  productFilter: ProductSearchReturnFilter;
}

export interface ProductSearchReturnOption {
  totalCnt: number;
  optionList: ProductSearchOption[];
}

export interface ProductSearchOption {
  key: string;
  productTypeKey: string;
  displayName: string;
  isEnabled: boolean;
  isSelected: boolean;
  hasChildren: boolean;
  cnt: number;
  childrenList: ProductSearchOption[];
}

export interface ProductSearchReturnFilter {
  displayName: string;
  key: string;
  productCnt: number;
  pageSize: number;
  pageCnt: number;
  pageCur: number;
  totalCnt: number;
  filterList: ProductSearchReturnFilterRow[];
  headerList: ProductSearchReturnHeaderCell[];
  productList: ProductSearchReturnResult[];
}

export interface ProductSearchReturnFilterRow {
  key: string;
  cellCnt: number;
  cellList: ProductSearchReturnFilterCell[];
}

export interface ProductSearchReturnFilterCell {
  key: string;
  id: number;
  name: string;
  displayName: string;
  cellCss: string;
  minName: string;
  maxName: string;
  group: string;
  type: number;
  unit: string;
  optionMin: string;
  optionMax: string;
  isApplied: boolean;
  appliedValue: string;
  appliedUnitValue: string;
  optionList: ProductSearchReturnFilterOption[];
  unitList: ProductSearchReturnFilterOption[];
}

export interface ProductSearchReturnFilterOption {
  key: string;
  name: string;
  value: string;
  isEnabled: boolean;
  isSelected: boolean;
}

export interface ProductSearchReturnHeaderCell {
  name: string;
  key: string;
  isSortEnabled: boolean;
}

export interface ProductSearchReturnResult {
  key: string;
  productCode: string;
  cellList: ProductSearchReturnResultCell[];
}

export interface ProductSearchReturnResultCell {
  key: string;
  type: string;
  name: string;
  value: string;
  target: string;
  url: string;
}

export interface ProductSearchBindingModel {
  Key: string;
  KeywordSearch: string;
  PageSize: number;
  PageNumber: number;
  SortKey: string;
  SortDirection: string;
  FilterList: ProductSearchBindingFilter[];
}

export interface ProductSearchBindingFilter {
  name: string;
  nameMin: string;
  nameMax: string;
  value: string;
  valueMax: string;
  filterType: number;
  rawValue?: string;
  unitValue?: string;
}

export const getProductSearchReturnAsync = async (
  binding: ProductSearchBindingModel
): Promise<ProductSearchReturn | undefined> => {
  const result = await http<ProductSearchReturn, ProductSearchBindingModel>({
    path: "/search/product",
    method: "POST",
    body: binding,
  });
  if (result.ok && result.body) {
    return result.body;
  } else {
    return undefined;
  }
};

export interface ProductSearchContextModel {
  productSearchData: ProductSearchBindingModel;
  setProductSearchData: Function;
}

export const ProductSearchContext =
  React.createContext<ProductSearchContextModel | null>(null);

export const useProductSearchContext = () =>
  React.useContext(ProductSearchContext);
