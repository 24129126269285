import React from "react";
import { ProductSearchTableHeader } from "./ProductSearchTableHeader";
import { ProductTableRow } from "./ProductTableRow";
import { ProductSearchFilter } from "./ProductSearchFilter";
import { ProductSearchReturn } from "../ProductSearchReturn";
import { Modal } from "../Modal";
import { DistributorContext } from "../ProductFilterReturn";
import { Loading } from "../Loading";

interface Props {
  result: ProductSearchReturn | undefined;
  filterGroupsOpen: Set<string>;
  setFilterGroupsOpen: Function;
}

export const ProductSearchRoot = ({
  result,
  filterGroupsOpen,
  setFilterGroupsOpen,
}: Props) => {
  const [distributor, setDistributor] = React.useState("");
  const [distributorLoading, setDistributorLoading] = React.useState(false);

  return (
    <>
      {result && (
        <div className="container">
          <Modal
            show={distributor ? true : false}
            onClose={() => {
              setDistributor("");
            }}
          >
            <div className="modal-body iframe-body">
              <Loading hide={!distributorLoading} />
              <iframe
                className="inv-iframe"
                title="Distributor Inventory"
                src={distributor}
                onLoad={() => setDistributorLoading(false)}
                style={{ display: distributorLoading ? "none" : "block" }}
              ></iframe>
            </div>
          </Modal>
          <div className="row product-results mt-4">
            {result &&
            result.productFilter &&
            result.productFilter.productList &&
            !result.productFilter.productList.length ? (
              <div className="justify-content-center text-center">
                <h3 className="text-secondary">Sorry, No Results Were Found</h3>
                <p>
                  We suggest checking your part number and spelling, or trying a
                  more general keyword
                </p>
              </div>
            ) : null}
            <div className="col-12 col-md-3">
              <ProductSearchFilter
                data={result}
                filterGroupsOpen={filterGroupsOpen}
                setFilterGroupsOpen={setFilterGroupsOpen}
              />
            </div>
            <div className="col-12 col-md-9">
              <div className="table-responsive">
                <table className="table product-table">
                  <ProductSearchTableHeader
                    data={result!.productFilter!.headerList}
                  />
                  <DistributorContext.Provider
                    value={{
                      distributor,
                      setDistributor,
                      setDistributorLoading,
                    }}
                  >
                    <ProductTableRow
                      data={result!.productFilter!.productList}
                    />
                  </DistributorContext.Provider>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
