import React from "react";
import {
  getLocationReturnAsync,
  LocationReturn,
  LocationBindingModel,
} from "../LocationReturn";
import { LocationResults } from "./LocationResults";
import { Pagination } from "../Pagination";
import { Loading } from "../Loading";

interface Props {
  searchKey: string;
  pageSize: number;
  presetFilter: string;
  filterVisible: boolean;
}

export const LocationSearchRoot = ({
  searchKey = "",
  pageSize,
  filterVisible = true,
  presetFilter = "",
}: Props) => {
  const defaultLocationModel: LocationBindingModel = {
    typ: presetFilter,
    kywrd: searchKey,
    pgsz: pageSize,
    pgnum: 0,
  };

  const [locationSearchData, setLocationSearchData] =
    React.useState<LocationBindingModel>(defaultLocationModel);
  const [locationResult, setLocationResult] = React.useState<LocationReturn>();
  const [loading, setLoading] = React.useState(true);
  const [search, setSearch] = React.useState(searchKey);
  const [type, setType] = React.useState(presetFilter);

  React.useEffect(() => {
    setLoading(true);
    const doGetLocationReturn = async (binding: LocationBindingModel) => {
      const result = await getLocationReturnAsync(binding);
      setLocationResult(result);
      setLoading(false);
    };
    doGetLocationReturn(locationSearchData);
  }, [locationSearchData]);

  return (
    <>
      <section id="variable">
        {filterVisible ? (
          <div className="container mt-5 mb-5">
            <div className="row">
              <div className="col-12 col-md-8">
                <form
                  className="search search-hero"
                  onSubmit={(e) => {
                    e.preventDefault();
                    setLocationSearchData({
                      ...locationSearchData,
                      kywrd: search,
                      typ: type,
                      pgnum: 0,
                    });
                  }}
                >
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      aria-label="Search Locations"
                      placeholder="Search Locations"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <button
                      type="submit"
                      className="btn btn-primary p-3 btn-search"
                    >
                      <i className="bi bi-search"></i>
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-12 col-md-2">
                <div className="d-flex flex-column">
                  <select
                    className="form-select search-hero-filter mt-3 mt-md-0"
                    aria-label="Search Locations"
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                      setLocationSearchData({
                        ...locationSearchData,
                        kywrd: search,
                        typ: e.target.value,
                        pgnum: 0,
                      });
                    }}
                  >
                    {locationResult?.filter?.typeOptionList.map((opt) => (
                      <option
                        key={opt.key}
                        value={opt.value}
                        disabled={!opt.isEnabled}
                      >
                        {opt.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </section>
      {loading ? <Loading /> : <LocationResults results={locationResult} />}
      {loading || locationResult?.pageCnt === 1 ? null : (
        <div className="container">
          <Pagination
            count={locationResult?.totalCnt || 0}
            pages={locationResult?.pageCnt || 0}
            currentPage={locationResult?.pageCur || 0}
            updatePageNumber={(pageNumber: number) =>
              setLocationSearchData({
                ...locationSearchData,
                pgnum: pageNumber,
              })
            }
          />
        </div>
      )}
    </>
  );
};
