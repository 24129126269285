import React from "react";
//import "./App.css";
//import { Test } from "./Test";
import { ProductFilterRoot } from "./product/ProductFilterRoot";
import { ResourceFilterRoot } from "./resource/ResourceFilterRoot";
import { ContentRoot } from "./content/ContentRoot";
import { CrossRefRoot } from "./cross-ref/CrossRefRoot";
import { SiteSearchRoot } from "./SiteSearchRoot";
import { SourceKeyContext } from "./ProductFilterReturn";
import { EventSearchRoot } from "./event/EventSearchRoot";
import { NewsSearchRoot } from "./news/NewsSearchRoot";
import { BlogSearchRoot } from "./blog/BlogSearchRoot";
import { LocationSearchRoot } from "./location/LocationSearchRoot";

interface Props {
  displayType: string;
  sourceKey: string;
  keyword: string;
  pageSize: number;
  urlParams: string;
  presetFilter: string;
  filterVisible: boolean;
}
function App({
  displayType,
  sourceKey,
  keyword,
  pageSize,
  urlParams,
  presetFilter,
  filterVisible,
}: Props) {
  const [sourceKeyValue, setSourceKey] = React.useState(sourceKey);
  return (
    <div className="App">
      {displayType === "product-filter" && (
        <SourceKeyContext.Provider value={{ sourceKeyValue, setSourceKey }}>
          <ProductFilterRoot
            sourceKey={sourceKey}
            pageSize={pageSize}
            urlParams={urlParams}
          />
        </SourceKeyContext.Provider>
      )}
      {displayType === "resource-filter" && (
        <ResourceFilterRoot
          sourceKey={sourceKey}
          searchKey={keyword}
          pageSize={pageSize}
        />
      )}
      {displayType === "content" && (
        <ContentRoot sourceKey={sourceKey} pageSize={pageSize} />
      )}
      {displayType === "cross-ref" && (
        <CrossRefRoot
          sourceKey={sourceKey}
          searchKey={keyword}
          pageSize={pageSize}
        />
      )}
      {displayType === "site-search" && (
        <SiteSearchRoot searchKey={keyword} pageSize={pageSize} />
      )}
      {displayType === "event" && (
        <EventSearchRoot searchKey={keyword} pageSize={pageSize} />
      )}
      {displayType === "news" && (
        <NewsSearchRoot searchKey={keyword} pageSize={pageSize} />
      )}
      {displayType === "blog" && (
        <BlogSearchRoot searchKey={keyword} pageSize={pageSize} />
      )}
      {displayType === "location" && (
        <LocationSearchRoot
          searchKey={keyword}
          pageSize={pageSize}
          presetFilter={presetFilter}
          filterVisible={filterVisible}
        />
      )}
    </div>
  );
}

export default App;
