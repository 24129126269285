import { http } from "./http";
import React from "react";

export interface CrossRefReturn {
  keyword: string;
  pageSize: number;
  pageCnt: number;
  pageCur: number;
  totalCnt: number;
  headerList: CrossRefReturnHeaderCell[];
  productList: CrossRefReturnResult[];
}

export interface CrossRefReturnHeaderCell {
  name: string;
  key: string;
  isSortEnabled: boolean;
}

export interface CrossRefReturnResult {
  key: string;
  productCode: string;
  cellList: CrossRefReturnResultCell[];
}

export interface CrossRefReturnResultCell {
  key: string;
  type: string;
  name: string;
  value: string;
  value2: string;
  target: string;
  url: string;
}

export interface CrossRefBindingModel {
  keyword: string;
  pageSize: number;
  pageNumber: number;
  sortKey: string;
  sortDirection: string;
}

export const getCrossRefReturnAsync = async (
  binding: CrossRefBindingModel
): Promise<CrossRefReturn | undefined> => {
  const result = await http<CrossRefReturn>({
    path: `/search/product-crossref?keyword=${binding.keyword || ""}&orderKey=${
      binding.sortKey || ""
    }&orderDirection=${binding.sortDirection || ""}&pageSize=${
      binding.pageSize || ""
    }&pageNumber=${binding.pageNumber || ""}`,
    method: "GET",
  });
  if (result.ok && result.body) {
    return result.body;
  } else {
    return undefined;
  }
};

export interface CrossRefContextModel {
  CrossRefData: CrossRefBindingModel;
  setCrossRefData: Function;
}

export const CrossRefContext = React.createContext<CrossRefContextModel | null>(
  null
);

export const useCrossRefContext = () => React.useContext(CrossRefContext);
