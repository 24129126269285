import React from "react";
import {
  ProductFilterContextModel,
  ProductFilterReturnHeaderCell,
  useProductFilterContext,
} from "../ProductFilterReturn";

interface Props {
  data: ProductFilterReturnHeaderCell[];
}

export const ProductTableHeader = ({ data }: Props) => {
  const { productFilterData, setProductFilterData } =
    useProductFilterContext() as ProductFilterContextModel;

  const { SortKey, SortDirection } = productFilterData;

  const sortIcon = (enabled: boolean, key: string) => {
    if (!enabled) {
      return null;
    }

    if (key === SortKey) {
      if (SortDirection === "ASC") {
        return (
          <span
            className="sort-header"
            onClick={() => {
              setProductFilterData({
                ...productFilterData,
                SortKey: key,
                SortDirection: "DESC",
              });
            }}
          >
            <i className="bi bi-caret-up-fill"></i>
          </span>
        );
      }
      return (
        <span
          className="sort-header"
          onClick={() => {
            setProductFilterData({
              ...productFilterData,
              SortKey: "",
              SortDirection: "",
            });
          }}
        >
          <i className="bi bi-caret-down-fill"></i>
        </span>
      );
    }

    return (
      <span
        className="sort-header sort-hover"
        onClick={() => {
          setProductFilterData({
            ...productFilterData,
            SortKey: key,
            SortDirection: "ASC",
          });
        }}
      >
        <i className="bi bi-caret-up"></i>
        <i className="bi bi-caret-down"></i>
      </span>
    );
  };

  return (
    <thead>
      <tr>
        {data.map((hdr) => (
          <th scope="col" key={hdr.key}>
            {hdr.name}
            {sortIcon(hdr.isSortEnabled, hdr.key)}
          </th>
        ))}
      </tr>
    </thead>
  );
};
