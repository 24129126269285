import React from "react";

interface Props {
  children: JSX.Element;
  show: boolean;
  onClose: Function;
}

export const Modal = ({ children, show = false, onClose }: Props) => {
  if (!show) {
    return null;
  }
  return (
    <div className="modal" tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header shadow-sm border-bottom-0">
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                onClose();
              }}
            ></button>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
