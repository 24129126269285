import React from "react";
import { LocationReturnResult } from "../LocationReturn";

interface Props {
  location: LocationReturnResult;
}

function generateBadges(tags: string[]) {
  return tags.map((tag) => (
    <span key={tag} className="badge rounded-pill bg-grey m-1">
      {tag}
    </span>
  ));
}

export const LocationCard = ({ location }: Props) => {
  return (
    <div className="col-md-4 col-sm-6 mb-sm-4 mb-3">
      <div
        className="card bg-dark text-white card-background-full"
        style={{ backgroundImage: `url(${location.cardImage})` }}
      >
        <div className="card-img-overlay">
          <div className="card-body">
            <h3 className="card-title text-white">{location.name}</h3>
            {generateBadges(location.tagList)}
            <p className="card-text">{location.summary}</p>
            <p className="mb-0">
              <a href={location.ctaURL} className="d-flex align-items-center">
                {location.ctaLabel} <i className="svg-arrow-right ms-3"></i>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
