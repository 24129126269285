import React from "react";
import { ContentResult } from "./ContentResult";
import { ContentReturnResult } from "../ContentReturn";

interface Props {
  data: ContentReturnResult[];
}

export const ContentResultSection = ({ data }: Props) => (
  <div className="col-lg-12">
    {data.map((item, idx) => (
      <ContentResult key={`${item.name}-${idx}`} data={item} />
    ))}
  </div>
);
