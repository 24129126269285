import React, { useEffect } from "react";
import {
  ProductFilterReturnFilterCell,
  ProductFilterBindingFilter,
  ProductFilterContextModel,
  useProductFilterContext,
} from "../ProductFilterReturn";

interface Props {
  data: ProductFilterReturnFilterCell;
}

export const ProductFilterInputOption = ({ data }: Props) => {
  const { productFilterData, setProductFilterData } =
    useProductFilterContext() as ProductFilterContextModel;

  const [filterValue, setFilterValue] = React.useState<string>("");
  useEffect(() => {
    const existingFilter =
      productFilterData &&
      productFilterData.FilterList.find((f) => f.name === data.group);

    const currentFilterValue = existingFilter?.value || "";
    setFilterValue(currentFilterValue);
  }, [productFilterData, data]);

  const existingFilter = () => {
    return (
      productFilterData &&
      productFilterData.FilterList.find((f) => f.name === data.group)
    );
  };

  return (
    <div className="mb-3 d-flex">
      <div className="flex-grow-1 w-100">
        <label className="form-label">{data.displayName}</label>
        <select
          className="form-control"
          value={filterValue}
          onChange={(e) => {
            let newFilterList = [...productFilterData.FilterList];
            if (existingFilter() && e.target.value !== "") {
              newFilterList = productFilterData.FilterList.map(
                (f: ProductFilterBindingFilter) => {
                  if (f.name === data.group) {
                    return {
                      ...f,
                      value: e.target.value,
                    };
                  }
                  return f;
                }
              );
            } else {
              if (e.target.value !== "") {
                const newFilter: ProductFilterBindingFilter = {
                  name: data.group,
                  nameMin: data.minName,
                  nameMax: data.maxName,
                  value: e.target.value,
                  valueMax: "",
                  filterType: data.type,
                };
                newFilterList.push(newFilter);
              } else {
                newFilterList = newFilterList.filter(
                  (f: ProductFilterBindingFilter) => f.name !== data.group
                );
              }
            }

            setProductFilterData({
              ...productFilterData,
              FilterList: newFilterList,
              PageNumber: 0,
            });
          }}
        >
          {data.optionList.map((d) => (
            <option value={d.value} key={d.key} disabled={!d.isEnabled}>
              {d.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
