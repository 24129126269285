import React from "react";
import ReactDOM from "react-dom";
import "./css/bootstrap.min.css";
import "./css/index.css";
import "./css/custom.css";
import "./css/pagination.css";
import "./css/breadcrumb.css";
import "./css/hero-plain.css";
import "./css/product-filters.css";
import "./css/product-table.css";
import "./css/resource-filters-table.css";
import "./css/range-slider.css";
import "./css/loading.css";
import "./css/hero.css";
import "./css/modal.css";
import "./css/card-background-top.css";
import "./css/news.css";
import "./css/news-events-listing.css";
import "./css/card-background-full.css";
import "./css/locations-listing.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./init-sticky-header";
//import { Test } from "./Test";
//import { TestComponent } from "./TestComponent";

const targetDivName = "root";
const targetDiv = document.getElementById(targetDivName);
const pDisplayType: string =
  targetDiv?.getAttribute("data-display-type") ?? "location";
const pSourceKey: string = targetDiv?.getAttribute("data-source-key") ?? "TEST";
const pKeyword: string = targetDiv?.getAttribute("data-keyword") ?? "";
const pPagesize: number = parseInt(
  targetDiv?.getAttribute("data-pagesize") || "50"
);
const pUrlParams: string =
  targetDiv?.getAttribute("data-source-urlparams") ?? "";
const pFilterTypeId: string = targetDiv?.getAttribute("data-type-id") ?? "";
const pFilterVisible: boolean =
  targetDiv?.getAttribute("data-filter-visible") === "true";

ReactDOM.render(
  <React.StrictMode>
    <App
      displayType={pDisplayType}
      sourceKey={pSourceKey}
      keyword={pKeyword}
      pageSize={pPagesize}
      urlParams={pUrlParams}
      presetFilter={pFilterTypeId}
      filterVisible={pFilterVisible}
    />
  </React.StrictMode>,
  targetDiv
);

/*
ReactDOM.render(
  <React.StrictMode>
    <Test />
  </React.StrictMode>,
  document.getElementById("test-xyz")
);
*/

/*
ReactDOM.render(
  <React.StrictMode>
    <TestComponent />
  </React.StrictMode>,
  document.getElementById("test-abc")
);
*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
