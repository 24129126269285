import React from "react";
import { CrossRefTableHeader } from "./CrossRefTableHeader";
import { CrossRefTableRow } from "./CrossRefTableRow";
import { Pagination } from "../Pagination";
import {
  getCrossRefReturnAsync,
  CrossRefReturn,
  CrossRefBindingModel,
  CrossRefContext,
} from "../CrossRefReturn";
import { Loading } from "../Loading";
import { Modal } from "../Modal";
import { DistributorContext } from "../ProductFilterReturn";
import { lookForTable } from "../init-sticky-header";

interface Props {
  sourceKey: string;
  searchKey: string;
  pageSize: number;
}

export const CrossRefRoot = ({ searchKey = "", pageSize }: Props) => {
  const defaultBindingModel: CrossRefBindingModel = {
    keyword: searchKey,
    pageSize: pageSize,
    pageNumber: 0,
    sortKey: "",
    sortDirection: "",
  };
  const [CrossRefData, setCrossRefData] =
    React.useState<CrossRefBindingModel>(defaultBindingModel);
  const [result, setResult] = React.useState<CrossRefReturn>();
  const [keyword, setKeyword] = React.useState<string>(searchKey);
  const [searched, setSearched] = React.useState<boolean>(searchKey !== "");
  const [loading, setLoading] = React.useState(true);
  const [distributor, setDistributor] = React.useState("");
  const [distributorLoading, setDistributorLoading] = React.useState(false);

  const updatePageNumber = (pageNumber: number) => {
    setCrossRefData({
      ...CrossRefData,
      pageNumber,
    });
  };

  React.useEffect(() => {
    lookForTable(0);
  });

  React.useEffect(() => {
    setLoading(true);
    const doGetCrossRefReturn = async (binding: CrossRefBindingModel) => {
      const result = await getCrossRefReturnAsync(binding);
      setResult(result);
      setLoading(false);
    };
    doGetCrossRefReturn(CrossRefData);
  }, [CrossRefData]);

  return (
    <div style={{ padding: "1em" }}>
      <section className="variable">
        <Modal
          show={distributor ? true : false}
          onClose={() => {
            setDistributor("");
          }}
        >
          <div className="modal-body iframe-body">
            <Loading hide={!distributorLoading} />
            <iframe
              className="inv-iframe"
              title="Distributor Inventory"
              src={distributor}
              onLoad={() => setDistributorLoading(false)}
              style={{ display: distributorLoading ? "none" : "block" }}
            ></iframe>
          </div>
        </Modal>
        <div className="container mb-5">
          <div className="row">
            <div className="col-12">
              {/* <h1 className="text-uppercase ff-condensed fw-bold">
                Search <span className="text-secondary">Cross Ref</span>
              </h1> */}
              <div className="col-12 col-md-8">
                <form
                  className="search search-hero"
                  onSubmit={(e) => {
                    setSearched(true);
                    e.preventDefault();
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      formLocation: "body",
                      event: "search_partcrossref",
                      searchTerm: keyword,
                    });
                    setCrossRefData({
                      ...CrossRefData,
                      keyword,
                      pageNumber: 0,
                    });
                  }}
                >
                  <div className="input-group">
                    <select
                      className="form-select bg-grey-lightest smallest"
                      aria-label="Search Select"
                      value="1"
                      onChange={() => {}}
                    >
                      <option value="0">Keyword/Part #</option>
                      <option value="1">Cross Ref Part</option>
                    </select>
                    <input
                      type="text"
                      className="form-control"
                      aria-label="Search Cross Ref"
                      placeholder="Search Cross Ref"
                      value={keyword}
                      onChange={(e) => {
                        setKeyword(e.target.value);
                      }}
                    />
                    <button
                      className="btn btn-primary p-3 btn-search"
                      type="submit"
                    >
                      <i className="bi bi-search"></i>
                    </button>
                  </div>
                </form>
                <p className="small mt-3 fst-italic">
                  Please note the TT Electronics part numbers returned by the
                  cross reference search are not guaranteed to be a direct
                  technical or mechanical equivalent to the competitor part.
                  Please review the TT datasheet for detailed specifications.
                </p>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <CrossRefContext.Provider value={{ CrossRefData, setCrossRefData }}>
            <div className="container-lg">
              <div className="row mt-4">
                {!loading && searched ? (
                  (result?.totalCnt || 0) > 0 ? (
                    <div className="col-12">
                      <h4 className="text-secondary">Results</h4>
                      <p className="mb-0 small">
                        {result?.totalCnt} Results Found
                      </p>
                    </div>
                  ) : (
                    <div className="justify-content-center text-center">
                      <h3 className="text-secondary">
                        Sorry, No Results Were Found
                      </h3>
                      <p>
                        We suggest checking your part number and spelling, or
                        trying a more general keyword
                      </p>
                    </div>
                  )
                ) : null}
              </div>
              <div className="col-12 product-results">
                <div className="table-responsive">
                  <table className="table product-table">
                    <DistributorContext.Provider
                      value={{
                        distributor,
                        setDistributor,
                        setDistributorLoading,
                      }}
                    >
                      <CrossRefTableHeader data={result!.headerList} />
                      <CrossRefTableRow data={result!.productList} />
                    </DistributorContext.Provider>
                  </table>
                </div>
              </div>
            </div>
            <div className="container-lg mb-6">
              <Pagination
                count={result?.totalCnt || 0}
                pages={result?.pageCnt || 0}
                currentPage={result?.pageCur || 0}
                updatePageNumber={updatePageNumber}
              />
            </div>
          </CrossRefContext.Provider>
        )}
      </section>
    </div>
  );
};
