import React from "react";
import {
  ProductFilterContextModel,
  ProductFilterReturnFilterRow,
  useProductFilterContext,
} from "../ProductFilterReturn";

interface Props {
  data: ProductFilterReturnFilterRow[];
}

export const ProductFilterApplied = ({ data }: Props) => {
  const { productFilterData, setProductFilterData } =
    useProductFilterContext() as ProductFilterContextModel;
  const [copyClass, setCopyClass] = React.useState("");

  React.useEffect(() => {
    if (copyClass !== "") {
      setTimeout(() => {
        setCopyClass("");
      }, 2000);
    }
  }, [copyClass]);

  const appliedCount = data.reduce((acc, cur) => {
    return acc + cur.cellList.filter((c) => c.isApplied).length;
  }, 0);

  return (
    <div className="product_filter_applied">
      {data.map((rw) => (
        <div key={rw.key} className="product_filter_row">
          {rw.cellList
            .filter((q) => q.isApplied)
            .map((d) => (
              <span
                key={d.key}
                className="product_filter badge rounded-pill bg-grey-lightest border border-grey d-inline-block align-items-center text-black px-3"
              >
                {d.displayName}: {d.appliedValue}
                <button
                  type="button"
                  className="btn ps-1 pe-0 py-0 text-black"
                  onClick={() => {
                    setProductFilterData({
                      ...productFilterData,
                      FilterList: productFilterData.FilterList.filter(
                        (f) => f.name !== d.group
                      ),
                    });
                  }}
                >
                  <i className="bi bi-x-lg"></i>
                </button>
              </span>
            ))}
        </div>
      ))}
      {data?.length && appliedCount > 0 ? (
        <div className="pt-2">
          <div className="position-relative d-inline-block">
            <button
              type="button"
              id="copy-btn"
              className="btn btn-primary px-2"
              onClick={() => {
                const searchQuery = productFilterData.FilterList.reduce(
                  (queryString, filter, idx) => {
                    let q = `${filter.name}=${filter.value}`;
                    if (filter.filterType === 18) {
                      q = `${filter.name}=${filter.nameMin}:${filter.value}|${filter.nameMax}:${filter.value}`;
                    } else if (filter.filterType === 21) {
                      q = `${filter.name}=${filter.value}|${filter.valueMax}`;
                    }
                    return idx === 0
                      ? `${queryString}${q}`
                      : `${queryString}&${q}`;
                  },
                  "?"
                );
                let filterURL = `${document.location.protocol}//${document.location.host}${document.location.pathname}${searchQuery}`;
                filterURL = filterURL.replace(/\s/g, "%20");
                navigator.clipboard.writeText(filterURL);
                setCopyClass("active");
              }}
            >
              Copy Filters
            </button>
            <div className={`btn-tooltip ${copyClass}`}>
              Filters Copied to Clipboard
            </div>
            <button
              type="button"
              className="btn px-2 clear_filters"
              onClick={() => {
                setProductFilterData({
                  ...productFilterData,
                  FilterList: [],
                });
              }}
            >
              Clear Filters
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};
