import { http } from "./http";

export interface EventReturn {
  status: string;
  message: string;
  pageSize: number;
  pageCnt: number;
  pageCur: number;
  totalCnt: number;
  filter: EventReturnFilter;
  resultList: EventReturnResult[];
}

export interface EventReturnFilter {
  typeValue: number;
  keyword: string;
  typeOptionList: EventReturnFilterOption[];
}

export interface EventReturnFilterOption {
  key: string;
  name: string;
  value: string;
  isEnabled: boolean;
  isSelected: boolean;
}

export interface EventReturnResult {
  key: string;
  name: string;
  summary: string;
  cardImage: string;
  ctaLabel: string;
  ctaURL: string;
  date?: string;
  badge: string;
}

export interface EventBindingModel {
  typ: string;
  kywrd: string;
  pgsz: number;
  pgnum: number;
}

export const getEventReturnAsync = async (
  binding: EventBindingModel
): Promise<EventReturn | undefined> => {
  const result = await http<EventReturn>({
    // eslint-disable-next-line prettier/prettier
    path: `/event/search?typ=${binding.typ}&kywrd=${binding.kywrd || ""}&pgsz=${binding.pgsz || ""}&pgnum=${binding.pgnum || ""}`,
    method: "GET",
  });
  if (result.ok && result.body) {
    return result.body;
  } else {
    return undefined;
  }
};
