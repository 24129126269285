import React from "react";
import { ProductFilterInput } from "./ProductFilterInput";
import { ProductFilterReturnFilterRow } from "../ProductFilterReturn";

interface Props {
  data: ProductFilterReturnFilterRow[];
}

export const ProductFilterRow = ({ data }: Props) => (
  <>
    {data.map((rw) => (
      <div key={rw.key} className="row">
        {rw.cellList.map((d) => (
          <ProductFilterInput data={d} key={d.id} />
        ))}
      </div>
    ))}
  </>
);
