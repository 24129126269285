import React from "react";
import { CrossRefTableDataLink } from "./CrossRefTableDataLink";
import { CrossRefTableDataImage } from "./CrossRefTableDataImage";
import {
  CrossRefReturnResult,
  CrossRefReturnResultCell,
} from "../CrossRefReturn";

interface Props {
  data: CrossRefReturnResult[];
}

export const CrossRefTableRow = ({ data }: Props) => {
  const cellClassName = (cellData: CrossRefReturnResultCell) => {
    if (cellData.type === "image") {
      return "product-img py1";
    }
    if (cellData.name === "Description") {
      return "product-description";
    }
    if (cellData.name === "Datasheet") {
      return "file-icon fs-5 py-2";
    }
    if (cellData.name === "Distribution Inventory") {
      return "file-icon fs-5 py-2";
    }
    if (cellData.name === "RFQ") {
      return "file-icon fs-5 py-2";
    }
    return "";
  };

  return (
    <tbody>
      {data.map((rw, i) => (
        <tr key={`${rw.key}-${i}`}>
          {rw.cellList.map((d, i) => (
            <td key={`${d.key}-${i}`} className={cellClassName(d)}>
              {d.type === "text" && d.value}
              {d.type === "text-2line" && (
                <div>
                  <span
                    style={{
                      fontWeight:
                        d.name === "Competitor Part" ? "bold" : "normal",
                    }}
                  >
                    {d.value}
                  </span>
                  <br />
                  <span>{d.value2}</span>
                </div>
              )}
              {d.type === "link" && <CrossRefTableDataLink data={d} />}
              {d.type === "image" && (
                <CrossRefTableDataImage data={d} productCode={rw.productCode} />
              )}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};
