import React from "react";
import { ProductTableDataLink } from "./ProductTableDataLink";
import { ProductTableDataImage } from "./ProductTableDataImage";
import {
  ProductFilterReturnResult,
  ProductFilterReturnResultCell,
} from "../ProductFilterReturn";

interface Props {
  data: ProductFilterReturnResult[];
}
// Need to turn on tooltips, bootstrap example for enable: https://getbootstrap.com/docs/5.0/components/tooltips/

export const ProductTableRow = ({ data }: Props) => {
  const cellClassName = (cellData: ProductFilterReturnResultCell) => {
    if (cellData.type === "image") {
      return "product-img py-1";
    }
    if (cellData.name === "Part") {
      return "part-number";
    }
    if (cellData.name === "Description") {
      return "product-description";
    }
    if (cellData.name === "Datasheet") {
      return "file-icon fs-5 py-2";
    }
    if (cellData.name === "Distribution Inventory") {
      return "file-icon fs-5 py-2";
    }
    if (cellData.name === "RFQ") {
      return "file-icon fs-5 py-2";
    }
    return "";
  };

  return (
    <tbody>
      {data.map((rw) => (
        <tr key={rw.key}>
          {rw.cellList.map((d) => {
            if (d.name === "Description") {
              return (
                <td key={d.key} data-tooltip title={d.value}>
                  <div className="product-description">{d.value}</div>
                </td>
              );
            }
            return (
              <td key={d.key} className={cellClassName(d)}>
                {d.type === "text" && d.value}
                {d.type === "link" && <ProductTableDataLink data={d} />}
                {d.type === "image" && (
                  <ProductTableDataImage
                    data={d}
                    productCode={rw.productCode}
                  />
                )}
              </td>
            );
          })}
        </tr>
      ))}
    </tbody>
  );
};
