import React from "react";
import {
  getEventReturnAsync,
  EventReturn,
  EventBindingModel,
} from "../EventReturn";
import { EventResults } from "./EventResults";
import { Pagination } from "../Pagination";
import { Loading } from "../Loading";

interface Props {
  searchKey: string;
  pageSize: number;
}

export const EventSearchRoot = ({ searchKey = "", pageSize }: Props) => {
  const defaultEventModel: EventBindingModel = {
    typ: "",
    kywrd: searchKey,
    pgsz: pageSize,
    pgnum: 0,
  };

  const [eventSearchData, setEventSearchData] =
    React.useState<EventBindingModel>(defaultEventModel);
  const [eventResult, setEventResult] = React.useState<EventReturn>();
  const [loading, setLoading] = React.useState(true);
  const [search, setSearch] = React.useState(searchKey);
  const [type, setType] = React.useState(defaultEventModel.typ);

  React.useEffect(() => {
    setLoading(true);
    const doGetEventReturn = async (binding: EventBindingModel) => {
      const result = await getEventReturnAsync(binding);
      setEventResult(result);
      setLoading(false);
    };
    doGetEventReturn(eventSearchData);
  }, [eventSearchData]);

  return (
    <>
      <section id="variable">
        <div className="container mt-5">
          <div className="row">
            <div className="col-12 col-md-8">
              <form
                className="search search-hero"
                onSubmit={(e) => {
                  e.preventDefault();
                  setEventSearchData({
                    ...eventSearchData,
                    kywrd: search,
                    typ: type,
                    pgnum: 0,
                  });
                }}
              >
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Search Events"
                    placeholder="Search Events"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary p-3 btn-search"
                  >
                    <i className="bi bi-search"></i>
                  </button>
                </div>
              </form>
            </div>
            <div className="col-12 col-md-2">
              <div className="d-flex flex-column">
                <select
                  className="form-select search-hero-filter mt-3 mt-md-0"
                  aria-label="Search Events"
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value);
                    setEventSearchData({
                      ...eventSearchData,
                      kywrd: search,
                      typ: e.target.value,
                      pgnum: 0,
                    });
                  }}
                >
                  {eventResult?.filter?.typeOptionList.map((opt) => (
                    <option
                      key={opt.key}
                      value={opt.value}
                      disabled={!opt.isEnabled}
                    >
                      {opt.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </section>
      {loading ? <Loading /> : <EventResults results={eventResult} />}
      {loading ? null : (
        <div className="container">
          <Pagination
            count={eventResult?.totalCnt || 0}
            pages={eventResult?.pageCnt || 0}
            currentPage={eventResult?.pageCur || 0}
            updatePageNumber={(pageNumber: number) =>
              setEventSearchData({ ...eventSearchData, pgnum: pageNumber })
            }
          />
        </div>
      )}
    </>
  );
};
