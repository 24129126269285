import React from "react";
import { ContentFilterSection } from "./ContentFilterSection";
import { ContentResultSection } from "./ContentResultSection";
import { Pagination } from "../Pagination";
import {
  getContentReturnAsync,
  ContentReturn,
  ContentBindingModel,
} from "../ContentReturn";

interface Props {
  sourceKey: string;
  pageSize: number;
}

export const ContentRoot = ({ sourceKey, pageSize }: Props) => {
  const defaultBindingModel: ContentBindingModel = {
    keyword: "a",
    sort: "",
    pageSize: pageSize,
    pageNumber: 0,
  };
  const [ContentData, setContentData] =
    React.useState<ContentBindingModel>(defaultBindingModel);
  const [result, setResult] = React.useState<ContentReturn>();
  const [loading, setLoading] = React.useState(true);

  const updatePageNumber = (pageNumber: number) => {
    setContentData({
      ...ContentData,
      pageNumber,
    });
  };

  React.useEffect(() => {
    const doGetContentReturn = async (binding: ContentBindingModel) => {
      const result = await getContentReturnAsync(binding);
      setResult(result);
      setLoading(false);
    };
    doGetContentReturn(ContentData);
  }, [ContentData]);

  return (
    <div style={{ padding: "1em" }}>
      {loading ? (
        <div>loading</div>
      ) : (
        <div>
          {result && (
            <div>
              <h4>{result.keyword}</h4>
              <p className="mb-0 small">{result.totalCnt} Results Found</p>
              <div className="row">
                <div className="col-md-9" />
                <div className="col-md-2" style={{ marginBottom: "1em" }}>
                  <ContentFilterSection
                    contentData={ContentData}
                    setContentData={setContentData}
                  />
                </div>
                <div className="col-md-1" />
              </div>
              <div className="row">
                <div className="col-md-1" />
                <div className="col-md-10">
                  <ContentResultSection data={result!.resultList} />
                </div>
                <div className="col-md-1" />
              </div>
              <div className="row">
                <div className="col-md-1" />
                <div className="col-md-10">
                  <Pagination
                    count={result?.totalCnt || 0}
                    pages={result?.pageCnt || 0}
                    currentPage={result?.pageCur || 0}
                    updatePageNumber={updatePageNumber}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
