import React from "react";
import {
  getBlogReturnAsync,
  BlogReturn,
  BlogBindingModel,
} from "../BlogReturn";
import { BlogResults } from "./BlogResults";
import { Pagination } from "../Pagination";
import { Loading } from "../Loading";

interface Props {
  searchKey: string;
  pageSize: number;
}

export const BlogSearchRoot = ({ searchKey = "", pageSize }: Props) => {
  const defaultBlogModel: BlogBindingModel = {
    typ: "",
    yr: "",
    kywrd: searchKey,
    pgsz: pageSize,
    pgnum: 0,
  };

  const [blogSearchData, setBlogSearchData] =
    React.useState<BlogBindingModel>(defaultBlogModel);
  const [blogResult, setBlogResult] = React.useState<BlogReturn>();
  const [loading, setLoading] = React.useState(true);
  const [search, setSearch] = React.useState(searchKey);
  const [type, setType] = React.useState("");
  const [year, setYear] = React.useState("");

  React.useEffect(() => {
    setLoading(true);
    const doGetBlogReturn = async (binding: BlogBindingModel) => {
      const result = await getBlogReturnAsync(binding);
      setBlogResult(result);
      setLoading(false);
    };
    doGetBlogReturn(blogSearchData);
  }, [blogSearchData]);

  return (
    <>
      <section id="variable">
        <div className="container mt-5">
          <div className="row">
            <div className="col-12 col-md-8">
              <form
                className="search search-hero"
                onSubmit={(e) => {
                  e.preventDefault();
                  setBlogSearchData({
                    ...blogSearchData,
                    kywrd: search,
                    typ: type,
                    yr: year,
                    pgnum: 0,
                  });
                }}
              >
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Search Blog"
                    placeholder="Search Blog"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary p-3 btn-search"
                  >
                    <i className="bi bi-search"></i>
                  </button>
                </div>
              </form>
            </div>
            <div className="col-12 col-md-2">
              <select
                className="form-select search-hero-filter mt-3 mt-md-0"
                aria-label="Search Blog"
                value={type}
                onChange={(e) => {
                  setType(e.target.value);
                  setBlogSearchData({
                    ...blogSearchData,
                    kywrd: search,
                    typ: e.target.value,
                    yr: year,
                    pgnum: 0,
                  });
                }}
              >
                {blogResult?.filter?.typeOptionList.map((opt) => (
                  <option
                    key={opt.key}
                    value={opt.value}
                    disabled={!opt.isEnabled}
                  >
                    {opt.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-md-2">
              <div className="d-flex flex-column">
                <select
                  className="form-select search-hero-filter mt-3 mt-md-0"
                  aria-label="Search Blog"
                  value={year}
                  onChange={(e) => {
                    setYear(e.target.value);
                    setBlogSearchData({
                      ...blogSearchData,
                      kywrd: search,
                      typ: type,
                      yr: e.target.value,
                      pgnum: 0,
                    });
                  }}
                >
                  {blogResult?.filter?.yearOptionList.map((opt) => (
                    <option
                      key={opt.key}
                      value={opt.value}
                      disabled={!opt.isEnabled}
                    >
                      {opt.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </section>
      {loading ? <Loading /> : <BlogResults results={blogResult} />}
      {loading ? null : (
        <div className="container">
          <Pagination
            count={blogResult?.totalCnt || 0}
            pages={blogResult?.pageCnt || 0}
            currentPage={blogResult?.pageCur || 0}
            updatePageNumber={(pageNumber: number) =>
              setBlogSearchData({ ...blogSearchData, pgnum: pageNumber })
            }
          />
        </div>
      )}
    </>
  );
};
