import React from "react";
import {
  DistributorContextModel,
  ProductFilterReturnResultCell,
  useDistributorContext,
} from "../ProductFilterReturn";

interface Props {
  data: ProductFilterReturnResultCell;
}

const generateLink = (
  data: any,
  setDistributor: Function,
  setDistributorLoading: Function
) => {
  if (["Datasheet", "RFQ"].includes(data.name)) {
    return (
      <a href={data.url} target={data.target}>
        <i className={data.value} />
      </a>
    );
  }

  if (data.name === "Distribution Inventory") {
    return (
      <div
        className="text-primary"
        onClick={() => {
          setDistributorLoading(true);
          setDistributor(data.url);
        }}
        style={{ cursor: "pointer" }}
      >
        <i className={data.value} />
      </div>
    );
  }

  return (
    <a href={data.url} target={data.target}>
      {data.value}
    </a>
  );
};

export const ProductTableDataLink = ({ data }: Props) => {
  const { setDistributor, setDistributorLoading } =
    useDistributorContext() as DistributorContextModel;

  return <>{generateLink(data, setDistributor, setDistributorLoading)}</>;
};
