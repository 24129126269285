import React from "react";
import { CrossRefReturnResultCell } from "../CrossRefReturn";

interface Props {
  data: CrossRefReturnResultCell;
  productCode: string;
}

export const CrossRefTableDataImage = ({ data, productCode }: Props) => (
  <img
    src={data.value}
    title={productCode}
    alt={productCode}
    onError={({ currentTarget }) => {
      currentTarget.onerror = null;
      currentTarget.src = "/images/default-product.png";
    }}
  />
);
