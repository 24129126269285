import React from "react";
import { ProductFilterInputOption } from "./ProductFilterInputOption";
import { ProductFilterInputRange } from "./ProductFilterInputRange";
import { ProductFilterInputRangeTwoInput } from "./ProductFilterInputRangeTwoInput";
import { ProductFilterInputRangeMin } from "./ProductFilterInputRangeMin";
import { ProductFilterInputRangeMax } from "./ProductFilterInputRangeMax";
import { ProductFilterInputCheckbox } from "./ProductFilterInputCheckbox";
import { ProductFilterInputMultiselect } from "./ProductFilterInputMultiselect";
import { ProductFilterReturnFilterCell } from "../ProductFilterReturn";
import { ProductFilterInputRedirect } from "./ProductFilterInputRedirect";

interface Props {
  data: ProductFilterReturnFilterCell;
}

export const ProductFilterInput = ({ data }: Props) => {
  switch (data.type) {
    case 6:
      return (
        <div className={data.cellCss}>
          <ProductFilterInputOption data={data} />
        </div>
      );
    case 18:
      return (
        <div className={data.cellCss}>
          <ProductFilterInputRange data={data} />
        </div>
      );
    case 21:
      return (
        <div className={data.cellCss}>
          <ProductFilterInputRangeTwoInput data={data} />
        </div>
      );
    case 8:
      return (
        <div className={data.cellCss}>
          <ProductFilterInputRangeMin data={data} />
        </div>
      );
    case 9:
      return (
        <div className={data.cellCss}>
          <ProductFilterInputRangeMax data={data} />
        </div>
      );
    case 5:
      return (
        <div className={data.cellCss}>
          <ProductFilterInputCheckbox data={data} />
        </div>
      );
    case 10:
      return (
        <div className={data.cellCss}>
          <ProductFilterInputMultiselect data={data} />
        </div>
      );
    case 31:
      return (
        <div className={data.cellCss}>
          <ProductFilterInputRedirect data={data} />
        </div>
      );
    default:
      return null;
  }
};
