import React from "react";
import { EventReturnResult } from "./EventReturn";
import { NewsReturnResult } from "./NewsReturn";

interface Props {
  card: EventReturnResult | NewsReturnResult;
  isNews: boolean;
}

export const CardResult = ({ card, isNews = false }: Props) => {
  const renderDate = () => {
    return (
      <p className="card-title text-secondary" style={{ marginBottom: "0p" }}>
        {card?.date}
      </p>
    );
  };

  const badgeClass = (badge: String) => {
    switch (badge) {
      case "Featured":
        return "bg-primary";
      case "Upcoming":
        return "bg-secondary";
      case "Past":
        return "bg-grey";
      default:
        return "";
    }
  };

  return (
    <div className="col-md-4 col-sm-6 mb-sm-4 mb-3" key={card.key}>
      <div className="card card-background-top ">
        <div
          className="card-img position-relative p-3 pb-0 pt-0 mb-1"
          style={{
            backgroundImage: `url(
            ${
              card.cardImage.charAt(0) === "~"
                ? card.cardImage.substring(1)
                : card.cardImage
            })`,
          }}
        ></div>
        {!isNews ? (
          <span
            className={`badge rounded-pill position-absolute ${badgeClass(
              card.badge || ""
            )}`}
          >
            {card.badge}
          </span>
        ) : null}
        <div className="card-body">
          {renderDate()}
          <h4 className="card-title text-secondary">{card.name}</h4>
          <p className="card-text">{card.summary}</p>
          <p className="mb-0">
            <a href={card.ctaURL} className="d-flex align-items-center">
              {card.ctaLabel}
              <span>
                <i className="svg-arrow-right ms-3"></i>
              </span>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
