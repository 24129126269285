import React from "react";
import { ContentReturnResult } from "../ContentReturn";

interface Props {
  data: ContentReturnResult;
}

export const ContentResult = ({ data }: Props) => (
  <div className="search-content-result py-4 border-bottom border-grey">
    <div>
      <h4>
        <a href={data.url}>{data.name}</a>
      </h4>
    </div>
    <div>
      <span className="content-type me-3">
        <i className={data.iconClass}></i> {data.type}
      </span>
      <br />
      <span>{data.description}</span>
    </div>
  </div>
);
